<template>
  <div class="container">
    <DuButton class="addNewButton" text="إضافة جديد" @click="addNew()"/>
    <DuList
      type="catsControlPanel"
      :exstra-parameters="{...state.extraParams}"
    />
  </div>
</template>

<script>
import DuButton from "../../components/DuButton";
import DuInput from "../../components/DuInput";
import {onBeforeMount, onMounted, reactive, watch} from "vue";
import DuInputUploader from "../../components/DuInputUploader";
import AjaxWorker from "../../helpers/AjaxWorker";
import FixedNotification from "../../components/plugins/fixedNotifications/FixedNotification";
import DuList from "../../components/DuList";
import {useRoute} from "vue-router";
import router from "../../router";
export default {
  name: "Cats",
  components: {DuList, DuInputUploader, DuInput, DuButton},
  setup() {
    const state = reactive({
      extraParams: {},
    });
    const route = useRoute();
    const addNew = () => {
      if(route.params.type !== undefined) {
        router.push('/controlPanel/addNewClassification?type=' + route.params.type);
      }
    }
    onBeforeMount(() => {
      rebuildData();
    });
    const rebuildData = () => {
      if(route.params.type !== undefined) {
        state.extraParams.type = route.params.type;
      }
    }

    watch(() => [route.query, route.params], () => {
      rebuildData();
    });

    return {
      state,
      addNew,
    }
  }
}
</script>

<style scoped>
.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
}
.addNewButton{
  place-self: end;
  margin: 20px;
}
</style>